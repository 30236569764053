html, body {
  background-color: #aec6cf;
  /* padding: 1rem 10rem; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

@media (max-height: 640px), screen and (orientation: portrait) {
  html, body {
    background-color: #aec6cf;
    padding: 0.5rem 20px;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0;
}

nav {
  margin-bottom: 200px;
}

/* .highlight:hover {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, red 50%);
  font-weight: 900;
  font-size: "25px";
  color: white,
} */